import {
  CreatedAt,
  FetchItemsCacheKey,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  ID,
  NanoID,
  UUID,
  UpdatedAt
} from '../../types';

export type EventTemplateID = ID;
export type EventTemplateUUID = UUID;
export type EventTemplateNanoID = NanoID;

export type EventTemplateCreatedAt = CreatedAt;
export type EventTemplateUpdatedAt = UpdatedAt;

export type EventEventTemplateType = EventEventTemplateTypes;

export type EventTemplateName = EventTemplateNames;

export type EventTemplateTriggerType = EventTemplateTriggerTypes;

export const enum EventTemplateNames {
  USER_AUTO_CHECK_IN = 'User auto-checkin',
  SC_TEMPLATE_AUTO_CHECK_IN = 'SC template auto checkin',
  SC_TEMPLATE_UPDATE = 'SC Template update',
  CC_CHECK_IN = 'Cc check-in',
  CC_INCITATION = 'Cc invitation'
}

export const enum EventEventTemplateTypes {
  SYSTEM = 'system',
  CLIENT = 'client',
  CONTRACTOR = 'contractor',
  NONE = 'none'
}

export const enum EventTemplateTriggerTypes {
  ON_SMART_CONTRACT_CREATE = 'on_smart_contract_create',
  ON_INVITED_TO_SMART_CONTRACT = 'on_invited_to_smart_contract',
  ON_CHECKED_IN_TO_SMART_CONTRACT = 'on_checked_in_to_smart_contract'
}

export type EventTemplateHandlerType = EventTemplateHandlerTypes;

export const enum EventTemplateHandlerTypes {
  CHECKIN_COMPANY_SMART_CONTRACT_TEMPLATE_TO_SMART_CONTRACT = 'checkin_company_smart_contract_template_to_smart_contract',
  INVITE_GENERAL_LEDGER_TO_SMART_CONTRACT = 'invite_general_ledger_to_smart_contract',
  CHECKIN_GENERAL_LEDGER_TO_SMART_CONTRACT = 'checkin_general_ledger_to_smart_contract'
}

export type FetchEventTemplatesCacheKey = FetchItemsCacheKey;

export type FetchEventTemplatesSort = FetchItemsSort;
export type FetchEventTemplatesPage = FetchItemsPage;
export type FetchEventTemplatesLimit = FetchItemsLimit;

export type FetchEventTemplatesFilters = {
  eventTemplateType?: EventEventTemplateTypes[];
};
